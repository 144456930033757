<script setup lang="ts">
import Icon from '~/components/molecules/Icon.vue'

interface Props {
  slug?: string
}

const props = defineProps<Props>()

const url = computed(() => `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${props.slug}&size=32`)
</script>

<template>
  <img
    v-if="slug"
    class="inline-block w-4"
    :src="url"
    :alt="slug"
  >
  <Icon
    v-else
    name="no-logo"
  />
</template>
